<template>
  <CModal
    v-if="show && !isLoading"
    :show="show"
    centered
    scrollable
    size="lg"
    class="promoModal"
  >
    <template #header>
      <span class="d-none"></span>
    </template>
    <div
      v-if="content.hasPin"
      class="promoModal__info_pin"
      v-html="$t('promo.available',{tariff: content.title[locale]})">
    </div>
    <CIcon
    class="promoModal__close"
    name="cil-x"
    @click.native="close" />
    <div class="promoModal__slider">
      <CCarousel
      :arrows="content.sliderData.length > 1"
      :dark="true"
      :class="{'--light': !darkMode}"
      animate>
        <CCarouselItem
        v-for="(item, index) in content.sliderData"
        :key="index">
          <VideoPlayer
            v-if="item.isVideo"
            class="d-block w-100"
            :video="{playlist: getVideoLink(item.videoName)}"
            :controls="false"
            autoplay
            loop
          ></VideoPlayer>
          <img
          v-else
          class="d-block w-100 promoModal__img"
          :src="require(`@/assets/img/${item.src}_${locale}.png`)"
          alt="slide 1"
          />
        </CCarouselItem>
        <!-- <CCarouselItem>
          <VideoPlayer
            class="d-block w-100 h-100"
            :video="{playlist: `${test}/videos/public/1.mp4`}"
            :controls="true"
          ></VideoPlayer>
          <img class="d-block w-100 h-100" src="https://coreui.io/vue/docs/images/vue.jpg" alt="slide 1"/>
        </CCarouselItem>
        <CCarouselItem>
          <img class="d-block w-100 h-100" src="https://coreui.io/vue/docs/images/react.jpg" alt="slide 2"/>
        </CCarouselItem>
        <CCarouselItem>
          <img class="d-block w-100 h-100" src="https://coreui.io/vue/docs/images/angular.jpg" alt="slide 3"/>
        </CCarouselItem> -->
      </CCarousel>
    </div>
    <div class="promoModal__info">
      <!-- <div
      class="promoModal__info_pin"
      v-html="$t('promo.available',{tariff: content.title[locale]})">
      </div> -->
      <span class="promoModal__info_title">
        {{ content.header?.[locale] }}
      </span>
      <div
      v-if="content.paragraphs"
      class="promoModal__info_paragraphs">
        <span
          class="promoModal__info_paragraphs-item"
          v-for="item in content.paragraphs"
          :key="item[locale]"
        >
          {{ item[locale] }}
        </span>
      </div>
      <div class="promoModal__info_advantages">
        <div class="promoModal__info_advantages-column">
          <div
          v-for="item in content?.advantages?.slice(0,3)"
          :key="item[locale]"
          class="promoModal__info_advantages-item">
            <div class="promoModal__info_advantages-item-icon">
              <CIcon
              name="check"
              />
            </div>
            <span>
              {{ item[locale] }}
            </span>
          </div>
        </div>
        <div
        v-if="content?.advantages?.length > 3"
        class="promoModal__info_advantages-column">
          <div
            v-for="item in content?.advantages?.slice(3)"
            :key="item[locale]"
            class="promoModal__info_advantages-item">
              <div class="promoModal__info_advantages-item-icon">
                <CIcon
                name="check"
                />
              </div>
              <span>
                {{ item[locale] }}
              </span>
            </div>
        </div>
      </div>
      <div
      v-if="content.afterParagraphs"
      class="promoModal__info_paragraphs promoModal__info_paragraphs--after">
        <span
          class="promoModal__info_paragraphs-item "
          v-for="item in content.afterParagraphs"
          :key="item[locale]"
        >
          {{ item[locale] }}
        </span>
      </div>
    </div>
    <template #footer>
      <div class="promoModal__footer_wrapper">
        <div class="promoModal__footer_section">
          <div
          v-if="currentTariff || showBuy"
          class="promoModal__footer_switch">
            <label
              class="mb-0 col-form-label-sm"
              @click="isYear = false">
              1 {{ $t('general.monthAbbr') }}
            </label>
            <CSwitch
              color="primary"
              shape="pill"
              :checked.sync="isYear"
              class="mx-3 switch-custom"
              id="switch"
            />
            <label
              class="mb-0 col-form-label-sm"
              @click="isYear = true">
              1 {{ $t('general.year') }}
            </label>
          </div>
          <div
          v-if="currentTariff || showBuy"
          class="promoModal__footer_discount-pin">
            {{$t('general.discount')}} 17%
          </div>
        </div>
        <div
        class="promoModal__footer_section">
          <div
          v-if="currentTariff || showBuy"
          class="promoModal__footer_month-price">
            <span class="promoModal__footer_price">
              {{ currentPriceObject?.price || proTariffPriceObject?.price }}$
            </span>
            <!-- <span class="promoModal__footer_month">
              / {{ $t('lk.subscribe.monthly') }}
            </span> -->
            <!-- <span
            v-if="isYear"
            class="promoModal__price-strike">
              {{ currentPriceObject.oldPrice }}$
            </span> -->
          </div>
          <CButton
            v-if="content.hasButton || showBuy"
            color="primary"
            @click="upgradeTariff"
          >
            {{$t('general.upgradeTo')}}
            <span
              class="text-uppercase">{{ content.title[locale] || 'Pro' }}
            </span>
          </CButton>
          <span
          class="mr-1"
          v-if="content.linkLabel && !showBuy">
            {{ content.linkLabel[locale] }}:
          </span>
          <CButton
            v-if="content.link && !showBuy"
            color="primary"
            @click="linkClickHandler(content.link)"
          >
            <span>
              {{ content.link.text[locale] }}
            </span>
          </CButton>
        </div>
      </div>
    </template>
    <ChangeTariff
      v-if="showExplanation"
      :show-explanation="showExplanation"
      :target-tariff="targetTariff"
      @updateShow="updateShow"
      @errorPopup="handleError"
      @tariffChanged="successClose"
    />
    <CModal
      :title="$t('lk.subscribe.modal.tariffChange')"
      centered
      :show="showPopupError"
      @update:show="showPopupError = $event"
    >
      <template v-if="paymentUrl">
        <p>{{ $t("lk.subscribe.modal.pageNoOpen") }}</p>
        <CLink
          class="text-primary"
          target="_blank"
          :href="paymentUrl"
          @click="showPopupError = false"
        >
          {{ paymentUrl }}
        </CLink>
      </template>
      <template v-else>
        <p>{{ errorMessage }}</p>
      </template>
      <template v-slot:footer>
        <div class="w-100">
          <CButton color="dark" @click="showPopupError = false">
            {{ $t("lk.subscribe.modal.cancel") }}
          </CButton>
        </div>
      </template>
    </CModal>
  </CModal>
</template>

<script>
import VideoPlayer from '@/components/videoEncrypt/VideoPlayer'
import axios from "axios";
import ChangeTariff from "@/components/subscribe/ChangeTariff";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "PromoModal",
  components: { ChangeTariff, VideoPlayer },
  props:{
    show: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return{
      isLoading: false,
      videos: [],
      tariffList: [],
      isYear: false,
      showExplanation: false,
      targetTariff: null,
      showPopupError: false,
      paymentUrl: "",
      errorMessage: "",
      mocData: {
        pro: {
          hasButton: true,
          hasPin: true,
          header: {
            ru: 'Мгновенный доступ к графику из всех вкладок скринера',
            en: 'Instant access to charts from all screener tabs'
          },
          advantages: [
            {
              ru: 'Полный функционал графиков Tradingview',
              en: 'Full functionality of Tradingview charts'
            },
            {
              ru: 'Создание оповещений на графике',
              en: 'Creating alerts on the chart'
            },
            {
              ru: 'Кастомные индикаторы Cryptovizor',
              en: 'Custom Cryptovizor indicators'
            },
            {
              ru: 'Таймфреймы от 1 мин. до 1 нед.',
              en: 'Timeframes from 1 min. to 1 week.'
            },
            {
              ru: 'Быстрая навигация между графиками с помощью горячих клавиш',
              en: 'Quick navigation between charts using hotkeys'
            },
            {
              ru: 'Дельта и кумулятивная дельта по всем альткоинам',
              en: 'Delta & Cumulative delta for all altcoins'
            },
          ],
        },
        pro_alerts: {
          hasButton: true,
          hasPin: true,
          header: {
            ru: 'Не пропустите важные события на рынке с помощью групповых оповещений',
            en: "Don't miss important market signals with group alerts"
          },
          advantages: [
            {
              ru: 'Создание оповещения по нескольким условиям',
              en: 'Creating a signals by multiple conditions'
            },
            {
              ru: 'Добавьте оповещения сразу на все монеты в вашем вотчлисте',
              en: 'Add a signal for all coins in your wotchlist at once'
            },
            {
              ru: 'Подпишитесь на публичные оповещения от опытных пользователей',
              en: 'Sign up for public alerts from experienced users'
            },
            {
              ru: 'Создавайте до 3-х приватных или публичных оповещений',
              en: 'Create up to 3 private or public alerts'
            },
            {
              ru: 'Получайте вознаграждения за ваши популярные публичные оповещения',
              en: 'Get rewards for your popular public alerts'
            },
          ],
        },
        trends: {
          linkLabel: {
            ru: 'Курс Daytrading Futures',
            en: 'Course Daytrading Futures'
          },
          link: {
            obj: {
              name: 'training-detail',
              params: { id: 1, slug: 'daytrading-futures' }
            },
            text: {
              ru: 'Перейти в обучение',
              en: 'Go to training'
            }
          },
          header: {
            ru: 'Готовая торговая стратегия перед вами!',
            en: "Your complete trading strategy is right here!"
          },
          advantages: [
            {
              ru: 'Ежедневные сигналы в отдельного бота: быстрая реакция на рыночные изменения.',
              en: 'Daily signals to a dedicated bot: rapid response to market changes.'
            },
            {
              ru: 'Автоматизированный поиск монет для ежедневной торговли: экономия времени и усилий.',
              en: 'Automated search for coins for daily trading: saves time and effort.'
            },
            {
              ru: 'Всегда знаешь, что и в каком направлении торговать: эффективность и точность.',
              en: 'Always know what to trade and in which direction: efficiency and accuracy.'
            },
          ],
          paragraphs: [
            {
              ru: '🚀 Скринер Cryptovizor разработан на основе проверенной торговой стратегии.',
              en: "🚀 The Cryptovizor screener is developed based on a proven trading strategy."
            },
            {
              ru: 'Уже более 3 лет мы успешно используем закрытый модуль тренды, который отслеживает ключевые изменения на рынке и помогает быстро принимать торговые решения.',
              en: 'For over three years, we have been successfully using the proprietary trends module, which tracks key market changes and assists in making swift trading decisions.'
            }
          ],
          afterParagraphs: [
            {
              ru: '🏆Модуль тренды является частью торговой стратегии, которая представлена в курсе Daytrading Futures.',
              en: "🏆 The trends module is part of the trading strategy presented in the Daytrading Futures course."
            }
          ]
        }
      }
    }
  },
  computed:{
    showBuy(){
      return !this.content.hasButton && this.userData.enableTrends
    },
    locale() {
      return this.$root.$i18n.locale;
    },
    ...mapGetters({
      darkMode: 'darkMode',
      stateContent: 'promo/promoModalContent',
      userData: 'user/userData',
      isGuest: 'user/isGuest'
    }),
    currentTariff(){
      return this.tariffList.find(item => item.id === this.stateContent.id)
    },
    currentPriceObject(){
      return this.isYear ? this.currentTariff?.variants.find(item => item.monthsCount == 12)
      : this.currentTariff?.variants.find(item => item.monthsCount == 1)
    },
    proTariff(){
      const proTariffObject = this.tariffList?.find(item => item.id === 'pro')
      return {
        ...proTariffObject,
        title: { ...proTariffObject?.name },
        tariff_id: proTariffObject?.id
      }
    },
    proTariffPriceObject(){
      return this.isYear ? this.proTariff?.variants?.find(item => item.monthsCount == 12)
      : this.proTariff?.variants?.find(item => item.monthsCount == 1)
    },
    content(){
      return {
        ...this.currentTariff,
        isTraining: false,
        tariff_id: this.currentTariff?.id,
        title: { ...this.currentTariff?.name },
        tariff_label: null,
        //in place of stateContenet
        ...this.mocData[this.currentTariff?.id || this.stateContent.id],
        sliderData: this.stateContent.sliderData
      }
    }
  },
  async created(){
    await this.getTariffs();
  },
  methods:{
    linkClickHandler(link){
      this.$router.push(link.obj)
      this.toggleShowPromoModal({show: false})
    },
    getVideoLink(videoName){
      return `${process.env.VUE_APP_BACK_URL}/videos/public/${videoName}.mp4`
    },
    getTariffs() {
      this.isLoading = true
      return axios
        .get("/api/v2/tariffs")
        .then(({ data, status }) => {
          if (status < 300) {
            this.tariffList = data.data;
            if(data?.promotions){
              this.userData.setPromotions(data.promotions || [], data.promoSummary || {})
            }
            this.isLoading = false
          }
        })
    },
    handleError(error) {
      this.showPopupError = true;
      if (error.type === "block") {
        this.paymentUrl = error.message;
      } else {
        this.errorMessage = error.message;
      }
    },
    upgradeTariff(){
      if(this.isGuest){
        this.toggleShowLoginModal({show: true})
        return
      }
      this.targetTariff = { ... (this.showBuy ? this.proTariff : this.content) }
      this.targetTariff.month_count = this.isYear ? 12 : 1
      this.updateShow(true)
    },
    updateShow(showStatus) {
      this.showExplanation = showStatus;
    },
    ...mapActions({
      toggleShowPromoModal: 'promo/toggleShowPromoModal',
      toggleShowLoginModal: 'user/toggleShowLoginModal',
    }),
    close(){
      this.toggleShowPromoModal({show: false})
    },
    async successClose(){
      this.$root.$emit('promoTariffChanged')
      this.toggleShowPromoModal({show: false})
      window.location.reload();
    }
  }
}
</script>

<style lang="scss" scoped>
.--light{
  /deep/ .carousel-control-next,
  /deep/ .carousel-control-prev{
    filter: invert(1);
  }

}
.promoModal{
  z-index: 9999;
  position: relative;
  /deep/ .modal-header{
    display: none;
  }
  /deep/ .modal-content{
    max-width: 698px;
  }
  /deep/ .modal-footer{
    min-height: 68px;
  }
  &__info_pin{
      padding: 5px 12px;
      border-radius: 50px;
      background-color: var(--success);
      width: fit-content;
      position: absolute;
      top: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  &__price-strike{
    vertical-align: top;
    font-size: 1.15rem;
    position: relative;
    opacity: 0.3;
    margin-left: 10px;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      transform: rotate(330deg);
      background: var(--white);
    }
  }
  &__close{
    position: absolute;
    cursor: pointer;
    user-select: none;
    right: 1rem;
    &:hover {
      color: $danger;
    }
  }
  &__slider{
    margin-top: 26px;
    margin-bottom: 30px;
    /deep/ .carousel-inner{
      width: 90%;
    }
    /deep/ .carousel{
      display: flex;
      justify-content: center;
    }
    /deep/ .carousel-control-next{
      justify-content: flex-end;
      cursor: pointer;
    }
    /deep/ .carousel-control-prev{
      justify-content: flex-start;
      cursor: pointer;
    }
  }
  &__info{
    display: flex;
    flex-direction: column;
    position: relative;
    &_title{
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 32px;
    }
    &_advantages{
      display: flex;
      column-gap: 10px;
    }
    &_advantages-item{
      display: flex;
      align-items: baseline;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      & span{
        opacity: 0.8;
      }
    }
    &_advantages-column{
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      margin-bottom: 12px;
    }
    &_advantages-item-icon{
      width: 17px;
      height: 17px;
      border-radius: 50%;
      background-color: var(--primary);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 11px;
      flex-shrink: 0;
      & svg{
        width: 15px !important;
        height: 15px !important;
        color: white;
      }
    }
    &_paragraphs{
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      &--after{
        margin-bottom: 0;
        margin-top: 15px;
      }
    }
  }
  &__footer{
    &_wrapper{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
    &_section{
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include media-breakpoint-down(md){
        margin-bottom: 15px;
        width: 100%;
      }
    }
    &_switch{
      display: flex;
      align-items: center;
    }
    &_discount-pin{
      display: flex;
      align-items: center;
      padding: 4px 6px;
      border: 1px solid var(--primary);
      border-radius: 50px;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.5px;
      margin-left: 14px;
    }
    &_month-price{
      display: flex;
      align-items: flex-start;
      margin-right: 24px;
    }
    &_price{
      font-weight: 500;
      font-size: 30px;
      line-height: 36px;
      letter-spacing: -1px;
    }
    &_month{
      font-weight: 400;
      font-size: 13px;
      line-height: 110%;
      opacity: 0.5;
      text-transform: lowercase;
      margin-top: 3px;
      margin-left: 5px;
    }
  }
  &__img{
    height: 340px;
    object-fit: contain;
  }
}
.switch-custom {
  /deep/ .c-switch-slider {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
  }
}
</style>
