var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show && !_vm.isLoading
    ? _c(
        "CModal",
        {
          staticClass: "promoModal",
          attrs: { show: _vm.show, centered: "", scrollable: "", size: "lg" },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [_c("span", { staticClass: "d-none" })]
                },
                proxy: true,
              },
              {
                key: "footer",
                fn: function () {
                  return [
                    _c("div", { staticClass: "promoModal__footer_wrapper" }, [
                      _c("div", { staticClass: "promoModal__footer_section" }, [
                        _vm.currentTariff || _vm.showBuy
                          ? _c(
                              "div",
                              { staticClass: "promoModal__footer_switch" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "mb-0 col-form-label-sm",
                                    on: {
                                      click: function ($event) {
                                        _vm.isYear = false
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " 1 " +
                                        _vm._s(_vm.$t("general.monthAbbr")) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("CSwitch", {
                                  staticClass: "mx-3 switch-custom",
                                  attrs: {
                                    color: "primary",
                                    shape: "pill",
                                    checked: _vm.isYear,
                                    id: "switch",
                                  },
                                  on: {
                                    "update:checked": function ($event) {
                                      _vm.isYear = $event
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "mb-0 col-form-label-sm",
                                    on: {
                                      click: function ($event) {
                                        _vm.isYear = true
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " 1 " +
                                        _vm._s(_vm.$t("general.year")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.currentTariff || _vm.showBuy
                          ? _c(
                              "div",
                              {
                                staticClass: "promoModal__footer_discount-pin",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("general.discount")) +
                                    " 17% "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        { staticClass: "promoModal__footer_section" },
                        [
                          _vm.currentTariff || _vm.showBuy
                            ? _c(
                                "div",
                                {
                                  staticClass: "promoModal__footer_month-price",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "promoModal__footer_price" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.currentPriceObject?.price ||
                                              _vm.proTariffPriceObject?.price
                                          ) +
                                          "$ "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.content.hasButton || _vm.showBuy
                            ? _c(
                                "CButton",
                                {
                                  attrs: { color: "primary" },
                                  on: { click: _vm.upgradeTariff },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("general.upgradeTo")) +
                                      " "
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "text-uppercase" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.content.title[_vm.locale] || "Pro"
                                        ) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.content.linkLabel && !_vm.showBuy
                            ? _c("span", { staticClass: "mr-1" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.content.linkLabel[_vm.locale]) +
                                    ": "
                                ),
                              ])
                            : _vm._e(),
                          _vm.content.link && !_vm.showBuy
                            ? _c(
                                "CButton",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.linkClickHandler(
                                        _vm.content.link
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.content.link.text[_vm.locale]
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            2869352751
          ),
        },
        [
          _vm.content.hasPin
            ? _c("div", {
                staticClass: "promoModal__info_pin",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("promo.available", {
                      tariff: _vm.content.title[_vm.locale],
                    })
                  ),
                },
              })
            : _vm._e(),
          _c("CIcon", {
            staticClass: "promoModal__close",
            attrs: { name: "cil-x" },
            nativeOn: {
              click: function ($event) {
                return _vm.close.apply(null, arguments)
              },
            },
          }),
          _c(
            "div",
            { staticClass: "promoModal__slider" },
            [
              _c(
                "CCarousel",
                {
                  class: { "--light": !_vm.darkMode },
                  attrs: {
                    arrows: _vm.content.sliderData.length > 1,
                    dark: true,
                    animate: "",
                  },
                },
                _vm._l(_vm.content.sliderData, function (item, index) {
                  return _c(
                    "CCarouselItem",
                    { key: index },
                    [
                      item.isVideo
                        ? _c("VideoPlayer", {
                            staticClass: "d-block w-100",
                            attrs: {
                              video: {
                                playlist: _vm.getVideoLink(item.videoName),
                              },
                              controls: false,
                              autoplay: "",
                              loop: "",
                            },
                          })
                        : _c("img", {
                            staticClass: "d-block w-100 promoModal__img",
                            attrs: {
                              src: require(`@/assets/img/${item.src}_${_vm.locale}.png`),
                              alt: "slide 1",
                            },
                          }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "promoModal__info" }, [
            _c("span", { staticClass: "promoModal__info_title" }, [
              _vm._v(" " + _vm._s(_vm.content.header?.[_vm.locale]) + " "),
            ]),
            _vm.content.paragraphs
              ? _c(
                  "div",
                  { staticClass: "promoModal__info_paragraphs" },
                  _vm._l(_vm.content.paragraphs, function (item) {
                    return _c(
                      "span",
                      {
                        key: item[_vm.locale],
                        staticClass: "promoModal__info_paragraphs-item",
                      },
                      [_vm._v(" " + _vm._s(item[_vm.locale]) + " ")]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _c("div", { staticClass: "promoModal__info_advantages" }, [
              _c(
                "div",
                { staticClass: "promoModal__info_advantages-column" },
                _vm._l(_vm.content?.advantages?.slice(0, 3), function (item) {
                  return _c(
                    "div",
                    {
                      key: item[_vm.locale],
                      staticClass: "promoModal__info_advantages-item",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "promoModal__info_advantages-item-icon",
                        },
                        [_c("CIcon", { attrs: { name: "check" } })],
                        1
                      ),
                      _c("span", [
                        _vm._v(" " + _vm._s(item[_vm.locale]) + " "),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _vm.content?.advantages?.length > 3
                ? _c(
                    "div",
                    { staticClass: "promoModal__info_advantages-column" },
                    _vm._l(_vm.content?.advantages?.slice(3), function (item) {
                      return _c(
                        "div",
                        {
                          key: item[_vm.locale],
                          staticClass: "promoModal__info_advantages-item",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "promoModal__info_advantages-item-icon",
                            },
                            [_c("CIcon", { attrs: { name: "check" } })],
                            1
                          ),
                          _c("span", [
                            _vm._v(" " + _vm._s(item[_vm.locale]) + " "),
                          ]),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
            _vm.content.afterParagraphs
              ? _c(
                  "div",
                  {
                    staticClass:
                      "promoModal__info_paragraphs promoModal__info_paragraphs--after",
                  },
                  _vm._l(_vm.content.afterParagraphs, function (item) {
                    return _c(
                      "span",
                      {
                        key: item[_vm.locale],
                        staticClass: "promoModal__info_paragraphs-item",
                      },
                      [_vm._v(" " + _vm._s(item[_vm.locale]) + " ")]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
          _vm.showExplanation
            ? _c("ChangeTariff", {
                attrs: {
                  "show-explanation": _vm.showExplanation,
                  "target-tariff": _vm.targetTariff,
                },
                on: {
                  updateShow: _vm.updateShow,
                  errorPopup: _vm.handleError,
                  tariffChanged: _vm.successClose,
                },
              })
            : _vm._e(),
          _c(
            "CModal",
            {
              attrs: {
                title: _vm.$t("lk.subscribe.modal.tariffChange"),
                centered: "",
                show: _vm.showPopupError,
              },
              on: {
                "update:show": function ($event) {
                  _vm.showPopupError = $event
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "w-100" },
                          [
                            _c(
                              "CButton",
                              {
                                attrs: { color: "dark" },
                                on: {
                                  click: function ($event) {
                                    _vm.showPopupError = false
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("lk.subscribe.modal.cancel")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1183420738
              ),
            },
            [
              _vm.paymentUrl
                ? [
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("lk.subscribe.modal.pageNoOpen"))),
                    ]),
                    _c(
                      "CLink",
                      {
                        staticClass: "text-primary",
                        attrs: { target: "_blank", href: _vm.paymentUrl },
                        on: {
                          click: function ($event) {
                            _vm.showPopupError = false
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.paymentUrl) + " ")]
                    ),
                  ]
                : [_c("p", [_vm._v(_vm._s(_vm.errorMessage))])],
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }